<template>
    <div class="wrapper">

        <section class="encabezado -section" data="distritos">

            <h2 class="title -white">En los Distritos</h2>

            <h3 class="subtitle">
                ¿Qué hacemos en cada Distrito y en toda la Ciudad?
            </h3>

        </section>

        <nav class="breadcrumb -section">
            <div class="container row-start">
                <router-link to="/">Inicio</router-link>
                <router-link :to="$route.fullPath">En los Distritos</router-link>
            </div>
        </nav>

        <a @click="back" class="back">Volver</a>

        <div class="container row-center contenedor-areas">

            <div class="contenedor-input" v-if="dominios.ejes">

                <multiselect v-model="areaseleccionada" :options="dominios.distritos" placeholder="Distritos" label="nombre"
                    :tagPosition="'bottom'" :showLabels="false" :showNoResults="false" track-by="nombre">
                </multiselect>

            </div>
        </div>

        <section class="contenedor-distritos">
            <div class="container" v-if="areaseleccionada">
                <distritos-map :active="areaseleccionada.nombre" @input="setDistrito"></distritos-map>
            </div>
        </section>


        <section class="contenedor-tip-objetivo row-center ais -section">
            <div class="container row-center">

                <div class="left" v-if="areaseleccionada">
                    <h2>{{ areaseleccionada.nombre }}</h2>
                    <!-- <progress-bar :title="'Actuaciones terminadas'" :percent="areaseleccionada.actuacionesterminadas*100/areaseleccionada.actuaciones" :color="'green'">
                    </progress-bar> -->
                    <progress-bar :title="'Actuaciones terminadas y en ejecución'" :percent="terminadasyenejecucion"
                        :color="'green'">
                    </progress-bar>
                    <count-down :title="'Tiempo restante del mandato'" :percent="homedata.tiempo_restante"
                        :color="'yellow'">
                    </count-down>
                </div>

                <div class="right">



                    <div class="contenedor-datos-graficas row-center">

                        <template v-if="type == 'actuaciones'">


                            <grafica-percent v-if="areaseleccionada" :total="areaseleccionada.actuaciones"
                                :label="'Planificadas'" :terminadas="areaseleccionada.actuacionesterminadas"
                                :ejecucion="areaseleccionada.actuacionesiniciadas" :planificadas="noiniciadas">
                            </grafica-percent>

                            <div class="stats row-center" v-if="areaseleccionada">
                                <article class="stat -small -terminadas">
                                    <strong><count-to :endVal="areaseleccionada.actuacionesterminadas"
                                            :separator="'.'"></count-to></strong>
                                    <span>Terminadas</span>
                                </article>

                                <article class="stat -small -ejecucion">
                                    <strong><count-to :endVal="areaseleccionada.actuacionesiniciadas"
                                            :separator="'.'"></count-to></strong>
                                    <span>En ejecución</span>
                                </article>

                                <article class="stat -small -no-iniciadas">
                                    <strong><count-to :endVal="noiniciadas" :separator="'.'"></count-to></strong>
                                    <span>No iniciadas</span>
                                </article>

                            </div>

                        </template>


                    </div>

                </div>

            </div>

        </section>

        <section class="tabla-areas -section" v-if="areaseleccionada">

            <div class="container">
                <header class="encabezado-tabla-areas row-start">
                    <h3 class="contador left -primary" @click="type = 'actuaciones'" :active="type == 'actuaciones'">
                        <span class="numero" v-if="areaseleccionada"><count-to
                                :endVal="actuaciones(areaseleccionada.id).length" separator='.'></count-to></span>
                        <span class="texto">Actuaciones</span>
                    </h3>
                </header>


                <resultados :type="'actuaciones'" :cansearch="true" :data="actuaciones(areaseleccionada.id)"
                    v-if="type == 'actuaciones'"></resultados>
                <resultados :type="'indicadores'" :cansearch="true" :data="indicadores(areaseleccionada.id)"
                    v-if="type == 'indicadores'"></resultados>

            </div>

        </section>

        <banner-ayudamos :data="['distritos', 'plan-estrategico', 'que-es-pog']"></banner-ayudamos>

    </div>
</template>


<script>

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

import resultados from '@/components/parts/resultados-busqueda'
import distritos from '@/components/parts/distritos'

import router from '@/router';

export default {
    name: 'distritos',
    mounted() {
        this.setRandomArea();
        this.requestHomeData();
        this.requestDominios();
    },
    data: () => ({
        areaseleccionada: '',
        type: 'actuaciones'
    }),
    computed: {
        ...mapGetters({
            homedata: 'getHomedata',
            dominios: 'getDominios',
            actuaciones: 'getActuacionesByDistrito',
            indicadores: 'getIndicadoresByDistrito'
        }),
        noiniciadas() {
            return Number(this.areaseleccionada.actuacionesretrasada) + Number(this.areaseleccionada.actuacionespendienteplanificar) + Number(this.areaseleccionada.actuacionesfuturas)
        },
        terminadasyenejecucion() {
            let terminadas = Number(this.areaseleccionada.actuaciones) - Number(this.noiniciadas);
            let porcentaje = (terminadas * 100) / Number(this.areaseleccionada.actuaciones);

            return Number(porcentaje);
            //return Number(this.areaseleccionada.actuaciones) - Number(this.noiniciadas) 
        }
    },
    methods: {
        ...mapActions(['requestHomeData', 'requestDominios']),
        setRandomArea() {
            if (this.dominios.distritos) {
                this.areaseleccionada = this.dominios.distritos[0];
            }
        },
        setDistrito(value) {
            this.areaseleccionada = this.dominios.distritos.filter(e => e.nombre == value)[0]
        },
        back() {
            router.go(-1);
        }
    },
    components: {
        'resultados': resultados,
        'distritos-map': distritos
    },
    watch: {
        'dominios.areas_accion'() {
            this.setRandomArea();
        }
    }
}
</script>